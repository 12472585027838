/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

import heroBg from './assets/contour-pattern.svg'
import sprinkle from './assets/sprinkle-pattern.svg'
import masmaderabackground from './assets/header-background-home-masmadera.webp'

export default {
  heroContainer: {
    position: `relative`,
    pt: [6, 7],
    '::before, ::after': {
      position: `absolute`,
      content: `" "`,
      width: `full`,
      height: `3/5`,
      top: 0,
      right: 0
    },
    // '::before': {
    //   zIndex: -3,
    //   bg: `white`,
    //   background: t => `linear-gradient(
    //     145deg,
    //     ${t.colors.alpha} 0%,
    //     ${t.colors.alphaDarker} 100%
    //   )`
    // },
    '::before': {
      zIndex: -3,
      bg: `black`,
      background: `url(${masmaderabackground}) repeat center 0`,
      backgroundSize: `100%`
    },
    '::after': {
      zIndex: -2,
      background: t => `linear-gradient(
        145deg,
        ${t.colors.alpha} 0%,
        ${t.colors.alphaDarker} 100%
      )`,
      opacity: 0.5
    }
  },
  servicesContainer: {
    px: [3, 4]
  },
  strategiesContainer: {
    position: `relative`,
    '::before': {
      content: `" "`,
      width: `full`,
      height: `150%`,
      position: `absolute`,
      top: 0,
      left: 0,
      zIndex: -1,
      background: t =>
        `url(${heroBg}) no-repeat center 0, linear-gradient(
          180deg,
          ${t.colors.alphaLighter} 0%,
          ${t.colors.background} 100%
        )`,
      backgroundSize: `100%`
    }
  },
  whyChooseUsContainer: {
    bg: `omegaLighter`,
    borderRadius: `xl`,
    py: 5,
    px: [4, 0]
  }
}
