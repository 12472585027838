import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Services from '@solid-ui-blocks/Features/Block05'
import TitleDesc from '@solid-ui-blocks/Features/Block07'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import WhyChooseUs from '@solid-ui-blocks/Features/Block01'
import Blog from '@solid-ui-blocks/Blog/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'
import WithRecentPosts from '@solid-ui-blocks/WithRecentPosts'
import Faq from '@solid-ui-blocks/Faq/Block01'
import Content from '@solid-ui-blocks/Content/Block01'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Carpintería Más Madera Zaragoza' />
      <ModalWithTabs content={content['contact']} />
      <Header content={content['header']} />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['cabecera']} />
        <Divider space='4' />
        <Container variant='cards.paper-lg' sx={styles.servicesContainer}>
          <Services content={content['feature-fabricantes']} />
        </Container>
      </Container>
      <Divider space='5' />
      <TitleDesc content={content['cabecera-fotos']} />
      <WithRecentPosts>
        <Blog />
      </WithRecentPosts>
      <Divider space='5' />
      <Container variant='wide' sx={styles.whyChooseUsContainer}>
        <WhyChooseUs content={content['servicios']} />
      </Container>
      <Divider space='5' />
      <FeatureOne content={content['feature-cnc']} />
      <Divider space='5' />
      {/* <Team content={content['team']} />
      <Divider space='5' /> */}
      <Container variant='narrow'>
        <Faq content={content['faq']} />
      </Container>
      <Divider space='5' />
      <Content content={content['que-hacemos']} />
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepageSiteBlockContent {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
